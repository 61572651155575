import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src="/assets/images/logo2.png" className="App-logo" alt="logo" />
        <div className="content">
          <h1>JEDYBO</h1>
          <p>(Jeunesse Dynamique Bobilis)</p>
          <br/>
          <p>Le site est en cours de développement. Revenez bientôt !</p>
        </div>
      </header>
    </div>
  );
}

export default App;
